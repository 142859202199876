<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
  <div id="page">
    <Header :add_class="['header-auto-show']" />

    <Footer logged />
    <!-- Page Title-->
    <div class="page-title page-title-fixed">
      <a
        @click="$router.go(-1)"
        class="page-title-icon rounded-s ms-3 bg-theme color-theme"
        ><i class="fas fa-chevron-left"></i
      ></a>
      <h1 class="font-18 mx-auto">Detail Pelamar</h1>
      <router-link
        :to="{ name: 'Dashboard' }"
        @click="menuOpen('menu-main')"
        data-menu="menu-main"
        class="page-title-icon rounded-s ms-3"
      ></router-link>
    </div>
    <div class="page-title-clear"></div>

    <!-- End Page Title-->
    <div class="page-content mt-4">
      <div class="card card-style">
        <div class="d-flex content mb-1">
          <!-- left side of profile -->
          <div class="flex-grow-1">
            <h2>{{ userData.first_name }} {{ userData.last_name }}</h2>
            <p class="mb-1 mt-3 me-3">{{ userData.mobile_no }}</p>
            <p class="line-height-s">{{ userData.email }}</p>
          </div>
          <!-- right side of profile. increase image width to increase column size-->
<!--          <img-->
<!--            :src="photoUrl"-->
<!--            width="115"-->
<!--            height="103"-->
<!--            class="rounded-circle mt-3 shadow-xl"-->
<!--          />-->
          <div
            class="card bg-transparent me-3"
          >
            <img
              :src="photoUrl"
              class="card-img rounded-s preload-img shadow-xl img-fluid entered loaded"
              alt=""
              style="height: 103px; width: 115px; object-fit: cover"
            />
            <div
              class="card-img-overlay p-0 d-flex align-items-end"
              v-if="userData.status_badge === 1"
            >
              <div
                class="w-100 text-center opacity-90"
                style="background-color: #663ba0 !important; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;"
              >
                <span class="fw-bold text-white" style="font-size: x-small">#OPENTOWORK</span>
              </div>
            </div>
          </div>
        </div>
        <!-- follow buttons-->
        <div class="content mb-0">
          <div class="row mb-0">
            <div class="col-6">
              <a
                @click="reqConnect(userData.id)"
                href="#"
                :class="{
                  'bg-yellow-dark': userStatus.connect,
                  'color-white': userStatus.connect,
                  'color-highlight': !userStatus.connect,
                  'border-highlight': !userStatus.connect,
                }"
                class="btn btn-full btn-sm rounded-s font-600 font-13"
                >{{ btnConnect }}</a
              >
            </div>
          </div>
        </div>

        <div class="divider mt-4 mb-0"></div>

        <div class="content">
          <p>
            {{ userData.introduction_letter }}
          </p>
        </div>
      </div>

      <!-- User CV -->
      <div v-if="cvUrl" class="card card-style pb-2">
        <div class="content mt-3 mb-2">
          <div class="d-flex">
            <div class="align-self-center me-auto">
              <h5 class="color-gray-dark">Silahkan Download<br />Cv Pelamar</h5>
            </div>
            <div class="align-self-center">
              <a
                :href="cvUrl"
                class="
                  btn btn-s
                  float-end
                  gradient-highlight
                  rounded-s
                  font-10 font-600
                "
              >
                <i class="fas fa-file-download select-all font-14 me-1"></i>
                Unduh CV</a
              >
            </div>
          </div>
          <div class="mt-3"><pdf :src="cvUrl"> </pdf></div>
        </div>
      </div>
      <!-- End User CV -->
    </div>
    <!-- End Page Content-->

    <!-- connect modals -->
    <div
      id="connect"
      class="menu menu-box-modal rounded-m"
      data-menu-hide="1500"
      data-menu-width="250"
      data-menu-height="170"
    >
      <h1 class="text-center mt-3 pt-2">
        <i class="fa fa-check-circle scale-icon fa-3x color-green-dark"></i>
      </h1>
      <h4 class="text-center pt-2">
        Permintaan berhasil <br />
        di kirim
      </h4>
    </div>
    <!-- End Connect modals-->
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import pdf from "pdfvuer";
export default {
  name: "DetailApplyJob",
  components: {
    Header,
    Footer,
    pdf,
  },
  data() {
    return {
      userData: "",
      userStatus: "",
      apiUrl: "/api/v1/job-seeker/user-detail/",
      userId: "",
      isLoading: true,
      btnConnect: "Connect",
      btnActive: true,
    };
  },
  computed: {
    photoUrl() {
      return this.userData.get_photo
        ? axios.defaults.baseURL + this.userData.get_photo
        : "/images/defaults/default_image.png";
    },
    cvUrl() {
      console.log(this.userData.get_resume);
      return this.userData.get_resume
        ? axios.defaults.baseURL + this.userData.get_resume
        : "";
    },
  },
  watch: {
    userStatus() {
      console.log(this.userStatus);
      this.userData = this.userStatus.user_data;
      console.log(this.userData);

      if (this.userStatus.req_connect && !this.userStatus.connect) {
        this.btnConnect = "Req Connect";
      }
      if (this.userStatus.req_connect && this.userStatus.connect) {
        this.btnActive = false;
      }
    },
  },
  mounted() {
    init_template();
    this.userId = this.$route.params.id;
    this.getUser();
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
  },
  methods: {
    menuOpen,
    menuClose,
    reqConnect(userId) {
      if (this.btnActive) {
        const data = {
          user: userId,
          connect: "1",
        };

        axios
          .post("/api/v1/job-seeker/connect/", data)
          .then((response) => {
            console.log("response data ", response);
            if (response.data.status === "success") {
              this.menuOpen("connect");
              setTimeout(() => {
                this.btnConnect = "Req Connect";
              }, 500);
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(
                "response data : " + JSON.stringify(error.response.data)
              );
            }
          });
      }
    },
    getUser() {
      var url = this.apiUrl + this.userId + "/";
      console.log(this.apiUrl);
      axios
        .get(url)
        .then((response) => {
          this.userStatus = response.data;
          this.photoUrl = this.user.get_photo;
          if (response.data[0].resume_upload) {
            this.cvUrl = axios.defaults.baseURL + response.data[0].get_resume;
            console.log(this.cvUrl);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "response data : " + JSON.stringify(error.response.data)
            );
          }
        });
    },
  },
};
</script>
